import { GET_EMAIL_BY_CODE, POST_RESET_PASSWORD_USER } from '../constants/routes';

export const sendInfoResetPassword = async (user, code) => {
	try {
			const options = { 
				method: 'PUT',
				 headers: {
		 		 	// 'invitation_code': `${code}`,
		 		 	'Content-type': 'application/json'
		 		 },
				body: JSON.stringify(user),
				mode: 'cors'
			};
			console.log(POST_RESET_PASSWORD_USER(code))
			console.log(user)
		 const response =  await fetch(POST_RESET_PASSWORD_USER(code), options)
		 const data = response.json();

		 return data;
	}
	catch (e) {
		console.log('error:', e)
	}
}

export const getEmailByCodeReset = async (code) => {
	try {
		 console.log(code)
		 	const options = {
		 		method: "GET",
		 		// headers: {
		 		// 	Authorization: `${code}`,
		 		// 	'Content-type': 'application/json'
		 		// },
		 		mode: 'cors'
		 	}
      console.log(GET_EMAIL_BY_CODE(code));
		 	const response =  await fetch (GET_EMAIL_BY_CODE(code), options);
		 	const data = response.json();
		 	return data;
	}
	catch (e) {
		console.log('error:', e)
	}
}
