import React, { Suspense }                                  from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect }                                          from 'react-redux';

const ResetView = React.lazy(() => import('./main/auth/auth.route'));


const Root: React.FC<any> = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Router>
                <Switch>
                    <Route component={ResetView} path=''/>
                </Switch>
            </Router>
        </Suspense>
    );
};

export default connect((state) => ({
}))(Root);

// export default connect(mapStateToProps, mapDispatchToProps)(Root);
