export const defaultValuesReset = {
  isLoading: false,
  responseInfoByCode: {},
  isLoaded: false,
  user: {
    password: "",
    rePassword: ""
  },
  responseUserResetPassword: {}
}
